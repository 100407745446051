import React from "react";
import { PM_STATIC_DATA } from "../utils/constants";
import Drawer from "./Drawer";
import PaymentInvoice from "./PaymentInvoice";

function SelectedPaymentDrawer(props, state) {
  const { paymentMethod, paymentInfo, processingFee, onGetProcessedFee, getCardNumberCall } = props;
  return (
    <Drawer onExit={props.onExit.bind(this)}>
      {paymentMethod && paymentInfo && (
        <div className="selected-payment-drawer">
          <div className="drawer-heading">
            <div className="payment-method-name">
              {PM_STATIC_DATA[paymentMethod.payment_method].title}
            </div>

            <div className="payment-method-icon">
              <img
                src={PM_STATIC_DATA[paymentMethod.payment_method].logo}
                alt={PM_STATIC_DATA[paymentMethod.payment_method].title}
              />
            </div>
          </div>

          <div className="drawer-body">
            <PaymentInvoice
              paymentInfo={paymentInfo}
              paymentMethod={paymentMethod}
              processingFee={processingFee}
              processedFeeFetch={onGetProcessedFee.bind(this)}
              getCardNumberCallCall={getCardNumberCall.bind(this)}
            />
          </div>

          <div className="drawer-footer">
            <button
              className="btn-primary"
              onClick={props.onProceed.bind(this)}
            >
              Proceed
            </button>
          </div>
        </div>
      )}
    </Drawer>
  );
}

export default SelectedPaymentDrawer;
