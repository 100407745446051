import React from "react";
import {PM_STATIC_DATA} from "../utils/constants";
import LabelValuePair from "./LabelValuePair";
import {refresh} from "../utils/CommonUtils";

function PaymentSummary(props) {
    const {
        paymentIdValue,
        paymentIdLabel,
        paymentAmount,
        paymentUserFullName,
        paymentCustomerReferrence,
        paymentUserEmail,
        paymentMethod,
        displayLineItems,
        lineItems,
        onGetProcessedFee,
        getCardNumberCallCall
    } = props;

    function getLineItems() {
        return <div className="line-items-wrapper d-flex flex-column">
            <div className={`label-value-pair mb-2 line-items-wrapper-text`}>
                <div className="label">Charged Items</div>
            </div>
            {
                lineItems.map(item => <div className="d-flex flex-column" key={item.description}>
                    <div className="d-flex justify-content-between mb-2 gap-5">
                        <div className="label-value-pair">
                            {item.quantity && <div className="value">{item.description}{' '}{`(x${item.quantity})`}</div>}
                            {!item.quantity && <div className="value">{item.description}</div>}
                        </div>
                        <div className="label-value-pair">
                            {item.quantity && <div className="value">{`$ ${(item.amount * item.quantity).toFixed(2)}`}</div>}
                            {!item.quantity && <div className="value">{`$ ${item.amount.toFixed(2)}`}</div>}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    }

    return (
        <div className="payment-summary-wrapper">
            <div className="payment-summary-wrapper-colomns d-flex justify-content-between">
                <div className="d-flex flex-column justify-content-between">
                    <div className="mb-3">
                        <LabelValuePair label="Customer Reference" value={paymentCustomerReferrence}/>
                    </div>
                    <div className="mb-3">
                        <LabelValuePair label="Name" value={paymentUserFullName}/>
                    </div>
                    <div className="mb-3">
                        <LabelValuePair label="Email" value={paymentUserEmail}/>
                    </div>
                    {
                        displayLineItems ? <div className="mb-3">
                            <LabelValuePair
                                label="Total Due"
                                value={`$ ${paymentAmount.toFixed(2)}`}
                                alignRight={false}
                            />
                        </div> : ""
                    }
                    {paymentMethod && (
                        <div className="payment-summary-row">
                            <div className="payment-summary-cell">
                                <div className="mb-2">
                                    <LabelValuePair
                                        label="Payment Method"
                                        value={PM_STATIC_DATA[paymentMethod.payment_method].title}
                                    />
                                </div>
                                <div className="mb-2">
                                    {onGetProcessedFee() &&
                                        <div className="mb-2">
                                            <LabelValuePair
                                                label="Processing Fee"
                                                value={`$ ${parseFloat(onGetProcessedFee()).toFixed(2)}`}
                                                alignRight={false}
                                            />
                                        </div>
                                    }
                                    {onGetProcessedFee() &&
                                        <div className="mb-2">
                                            <LabelValuePair
                                                label="Total"
                                                value={`$ ${(parseFloat(paymentAmount) + parseFloat(onGetProcessedFee())).toFixed(2)}`}
                                                alignRight={false}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="d-flex">
                    {!displayLineItems ? <div className="mb-3"><div className="payment-summary-cell">
                        <LabelValuePair
                            label="Total Due"
                            value={`$ ${paymentAmount.toFixed(2)}`}
                            alignRight={true}
                        />
                    </div></div> : getLineItems()}
                </div>
            </div>

        </div>
    );
}

export default PaymentSummary;
