import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/Alert.scss';
import { VscChromeClose } from "react-icons/vsc";
import { IoIosAlert } from "react-icons/io";
import { LuAlertTriangle } from "react-icons/lu";


const Alert = ({ type, message, onClose }) => {
  const getAlertClass = () => {
    switch (type) {
      case 'success':
        return 'alert-success';
      case 'error':
        return 'alert-error';
      case 'warning':
        return 'alert-warning';
      case 'info':
        return 'alert-info';
      default:
        return 'alert-default';
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 6000);

    return () => clearTimeout(timer);
  }, [onClose]
    
  );
    


  return (
    <div className={`alert ${getAlertClass()}`}>
      <LuAlertTriangle color="white" fontSize="1.5em"/>
      <span className="alert-message">      
      {message}</span>
      <VscChromeClose className="alert-close-btn" onClick={onClose}></VscChromeClose>   
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

Alert.defaultProps = {
  type: 'info',
  onClose: () => {
  
  }
};

export default Alert;