import React from "react";

import ReactDOM from "react-dom";

function Drawer(props, state) {
  return ReactDOM.createPortal(
    <div className="drawer-wrapper">
      <div
        className="drawer-backdrop"
        onClick={() => props.onExit()}
      ></div>

      <div className="drawer-content">{props.children}</div>
    </div>,
    document.body
  );
}

export default Drawer;
