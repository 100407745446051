import React from "react";

function LabelValuePair(props, state) {
  return (
    <div className={`label-value-pair ${props.alignRight ? " right" : ""}`}>
      <div className="label">{props.label}</div>
      <div className="value">{props.value}</div>
    </div>
  );
}

export default LabelValuePair;
