import React from "react";

function PaymentInvoice(props, state) {
  const { paymentMethod, paymentInfo, processingFee, processedFeeFetch, getCardNumberCallCall } = props;
  const isReady = paymentInfo;

  if (!isReady) {
    return "";
  }
  
  const total = () => {
    if (!paymentMethod) {
      return '--';
    } else if (paymentInfo.gross_amount && !processedFeeFetch()) {
      return `$ ${paymentInfo.gross_amount.toFixed(2)}`
    } else if (paymentInfo.gross_amount && processedFeeFetch()) {
      return `$ ${(parseFloat(paymentInfo.gross_amount) + parseFloat(processedFeeFetch())).toFixed(2)}`;
    }
  }

  return (
    <div className="payment-invoice">
      <ul className="invoice-item-list">
        {/*<li className="invoice-item">*/}
        {/*  <div className="invoice-item-row">*/}
        {/*    <div className="invoice-item-label">Amount</div>*/}
        {/*    {!paymentInfo.display_line_items && (*/}
        {/*      <div className="invoice-item-amount">*/}
        {/*        {`$ ${gross_amount.toFixed(2)}`}*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*  {paymentInfo.display_line_items && (*/}
        {/*    <ul className="invoice-item-breakdown">*/}
        {/*      {paymentInfo.line_items.map((item) => (*/}
        {/*        <li key={item.description} className="invoice-sub-item">*/}
        {/*          <div className="invoice-sub-item-label">*/}
        {/*            {item.description}*/}
        {/*          </div>*/}
        {/*          <div className="invoice-sub-item-amount">*/}
        {/*            {`$ ${item.amount.toFixed(2)}`}*/}
        {/*          </div>*/}
        {/*        </li>*/}
        {/*      ))}*/}
        {/*    </ul>*/}
        {/*  )}*/}
        {/*</li>*/}

        <li className="invoice-item">
          <div className="invoice-item-row">
            <div className="invoice-item-label">Amount to be paid</div>
            <div className="invoice-item-amount">
              {paymentInfo && paymentInfo.gross_amount != null ? `$ ${paymentInfo.gross_amount.toFixed(2)}` : "--"}
            </div>
          </div>
        </li>

        <li className="invoice-item">
          <div className="invoice-item-row">
            <div className="invoice-item-label">Processing Fee</div>
            <div className="invoice-item-amount">
              {(paymentMethod && processedFeeFetch()) ? `$ ${parseFloat(processedFeeFetch()).toFixed(2)}` : "--"}
            </div>
          </div>
        </li>

        <hr />

        {getCardNumberCallCall() &&
            <li className="invoice-item">
              <div className="invoice-item-row">
                <div className="invoice-item-label"><b>Card Number</b></div>
                <div className="invoice-item-amount">
                  <b>{getCardNumberCallCall()}</b>
                </div>
              </div>
            </li>
        }

        <li className="invoice-item">
          <div className="invoice-item-row total">
            <div className="invoice-item-label">Total</div>
            <div className="invoice-item-amount">
              {total()}
            </div>
          </div>
        </li>

      </ul>
    </div>
  );
}

export default PaymentInvoice;
