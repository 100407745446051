import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route, Routes, useParams} from 'react-router-dom';
import PaymentApp from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./components/ErrorPage";

const WrapperPaymentApp = () => {
    const { orderId } = useParams()
    return <PaymentApp orderId={orderId}/>
}
const RoutedApp = () => (
    <BrowserRouter>
        <Routes>
            <Route path="page/:orderId" element={<WrapperPaymentApp/>}/>
            <Route path="*" element={<ErrorPage customErrorMessage={'404 Not Found'}/>}/>
        </Routes>
    </BrowserRouter>
)


ReactDOM.render(
  <React.StrictMode>
    <RoutedApp />
  </React.StrictMode>,
  document.getElementById("payment-page-container")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
