import React from "react";
import _ from "lodash";
import "./App.scss";

import ErrorPage from "./components/ErrorPage";
import LoadingSpinner from "./components/LoadingSpinner";
import PageContent from "./components/PageContent";
import PaymentSummary from "./components/PaymentSummary";
import SelectedPaymentDrawer from "./components/SelectedPaymentDrawer";
import { isMobile } from "./utils/CommonUtils";
import { fetchOrderPaymentStatus, initializePayment, loadPaymentInfo, loadPaymentMethods } from "./utils/RequestHandlers";
import {
  ALIPAY,
  CHINAPAYMENTS,
  CREDITCARD,
  DIRECTDEBIT,
  LINK_ACCESS_TOKEN,
  UNIONPAY,
  WECHATPAY
} from "./utils/constants";
import usePageDataStore from "./store/PageDataStore";
import Alert from "./components/Alert";


class PaymentApp extends React.Component {
  handleWindowResize = () => {
    this.setState({ isMobile: isMobile() });
  };

   closeAlert = () => {
    this.setState({
      alert: false
      
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      // flags
      isMobile: isMobile(),
      isLoading: true,
      currentView: "payment-method-selection",
      isDrawerShown: false,
      isPaymentInfoLoaded: false,
      isPaymentMethodsLoaded: false,
      error: false,
      alert: false,
      // data
      orderId: this.props.orderId,
      paymentInfo: null,
      paymentMethods: [],
      paymentMethod: null,
      paymentTransactionId: null,
      paymentFrameUrl: null,
      paymentQRCode: null,
      paymentWXCodeURL: null,
      processedFee: null,
      cardNumber: null,
      errorMessage: null,
      errorHeading: null,
      alertMessage: null,
    };
  }

  loadHostedPaymentElementsScript() {
    // need hpfOptions declared in global scope,
    // prior to loading hpf script
    window.hpfOptions = null;
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_HOSTED_PAYMENTS_URLL;
    script.async = true;
    head.appendChild(script);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize.bind(this));
    this.loadHostedPaymentElementsScript();
    try {
      const params = this.props.orderId;
      const paymentInfo = await loadPaymentInfo(params);
      sessionStorage.setItem(LINK_ACCESS_TOKEN, paymentInfo.paymentInfo.access_token);
      const paymentMethodsFeeList = await loadPaymentMethods(paymentInfo.paymentInfo.access_token, paymentInfo.paymentInfo.gross_amount);
      document.title = _.get(paymentInfo, 'paymentInfo.merchant.page_configuration.title', 'Pay By Link | Novatti');
      const favicon = getFaviconEl();
      favicon.href = _.get(paymentInfo, 'paymentInfo.merchant.page_configuration.favicon_href', '/assets/novatti_logo.svg');
      const paymentMethods = {
        isPaymentMethodsLoaded: true,
        paymentMethods: this.getPaymentMethodList(paymentInfo.paymentInfo.merchant.payment_methods, paymentInfo.paymentInfo.currency),
      };

      usePageDataStore.getState().setInitialData({
        ...paymentInfo,
        ...paymentMethods,
        isLoading: false,
        paymentMethodsFeeList: paymentMethodsFeeList.feeList,
      })
      this.setState({
        ...paymentInfo,
        ...paymentMethods,
        isLoading: false,
        paymentMethodsFeeList: paymentMethodsFeeList.feeList,
      });
    } catch (error) {
      if (error.type === "INVALID_URL") {
        this.setState({
          error: true,
          isLoading: false,
          errorMessage: "Invalid Payment Link!"
        });
      } else if (error.type === "EXPIRED_URL") {
        this.setState({
          error: true,
          isLoading: false,
          errorMessage: error.message,
          errorHeading: "Expired Link"
        });
       } else {
        this.setState({
          error: true,
          isLoading: false,
        });
      }
    }
  }

  getPaymentMethodList(paymentMethods, currency) {
    let methods = paymentMethods
      .filter(method => method.payment_method_code === CREDITCARD/* || method.payment_method_code === DIRECTDEBIT*/)
      .sort((a, b) => {
        if (a.payment_method_code < b.payment_method_code) {
          return -1;
        }
        if (a.payment_method_code > b.payment_method_code) {
          return 1;
        }
        return 0;
      }).map((paymentMethod) => ({ payment_method: paymentMethod.payment_method_code, enabled: paymentMethod.enabled }));
    let chinapayments = this.getChinapaymentMethod(paymentMethods);
    if (chinapayments) {
      chinapayments.provider_transaction_types?.includes('WECHATPAY') && methods.push({ payment_method: WECHATPAY, enabled: true });
      (chinapayments.provider_transaction_types?.includes('ALIPAY') || chinapayments.provider_transaction_types?.includes('ALIPAY_PLUS')
        || chinapayments.provider_transaction_types?.includes('ALIPAY_ONLINE')) && methods.push({ payment_method: ALIPAY, enabled: true });
      chinapayments.provider_transaction_types?.includes('UNIONPAY') && methods.push({ payment_method: UNIONPAY, enabled: true });
    }
    return methods;
  }

  getChinapaymentMethod(paymentMethods) {
    return paymentMethods.find(
      method => method.payment_method_code === CHINAPAYMENTS && 
        method.enabled === true);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  getCardNumber() {
    return this.state.cardNumber;
  }

  onPollingError(errorMessage) {
    this.setState({
      error: true,
      errorMessage
    });
  }

  onCardNumberReceived(cardNumber) {
    this.setState({
      cardNumber: cardNumber
    });
  }

  getProcessedFee() {
    return this.state.processedFee;
  }

  onProcessedFeeCalculated(processedFee) {
    console.log(`Processed fee calculated ${processedFee}`)
    this.setState({
      processedFee: processedFee
    });
  }

  onPaymentMethodSelect(paymentMethod) {

    this.setState({
      isLoading: true,
    });
    fetchOrderPaymentStatus(
      this.state.paymentInfo.access_token,
      this.props.orderId
    )
      .then((response) => {
        if (response.orderPaymentStatus.pbl_payment_status === "PENDING") {  
          this.setState({
            alert: true,
            isLoading: false,
            alertMessage: "  Payment is in progress. Please try again later."
          
          });
        } else if (response.orderPaymentStatus.pbl_payment_status === "COMPLETED") {
          this.setState({
            error: true,
            isLoading: false,
            errorMessage: " ",
            errorHeading: "Payment already completed."
          });

        } else if (response.orderPaymentStatus.pbl_payment_status === "OPEN"){

          let appliedFee = null;
          if (paymentMethod && paymentMethod.payment_method === DIRECTDEBIT) {
            var results = this.state.paymentMethodsFeeList.find(fee => fee.payment_method === DIRECTDEBIT);
            if (results) {
              appliedFee = results.processing_fee;
            }
          }
          if (paymentMethod && paymentMethod.payment_method === ALIPAY) {
            var results = this.state.paymentMethodsFeeList.find(fee => fee.payment_method === ALIPAY);
            if (results) {
              appliedFee = results.processing_fee;
            }
          }
          if (paymentMethod && paymentMethod.payment_method === WECHATPAY) {
            var results = this.state.paymentMethodsFeeList.find(fee => fee.payment_method === WECHATPAY);
            if (results) {
              appliedFee = results.processing_fee;
            }
          }
          if (paymentMethod && paymentMethod.payment_method === UNIONPAY) {
            var results = this.state.paymentMethodsFeeList.find(fee => fee.payment_method === UNIONPAY);
            if (results) {
              appliedFee = results.processing_fee;
            }
          }

          this.setState({
            isLoading: false,
            paymentMethod: paymentMethod,
            isDrawerShown: false,
            processedFee: appliedFee,
            currentView: "payment-method-selection",
            paymentFrameUrl: ''
          });

        }
      });

  }

  onPaymentMethodProceed() {
    this.setState({
      isLoading: true,
    });
    initializePayment(
      this.state.paymentInfo.access_token,
      this.state.paymentMethod
    )
      .then((response) => {
        if(response.responseCode === 400){
          this.setState({
            error: true,
            isLoading: false,
            errorMessage: response.responseMessage,
            errorHeading: "Processing Error."
          });

        }

        if (!_.isEmpty(response.paymentTransactionId)) {
          this.setState({
            ...this.state,
            isLoading: false,
            isDrawerShown: false,
            currentView: "payment-form",
            paymentQRCode: response.paymentQRCode,
            paymentWXCodeURL: response.paymentWXCodeURL,
            paymentFrameUrl: response.paymentFrameUrl,
            paymentTransactionId: response.paymentTransactionId,
          });
        } else {
          this.setState({
            ...this.state,
            error: true,
            isLoading: false
          });
        }
      });
  }

  closeDrawer() {
    this.setState({ isDrawerShown: false, paymentMethod: null });
  }

  getLogoSrc(base64Data) {
    try {
      if (!base64Data) {
        return null; // Indicate no valid logo is provided
      }

      const decodedData = atob(base64Data.trim());

      // Check if the decoded data is SVG, PNG, or JPG
      const isSvg = decodedData.includes("<svg");
      const mimeType = base64Data.startsWith("/") ? 'image/jpeg' :
        base64Data.startsWith("i") ? 'image/png' : null;

      if (isSvg) {
        return { type: "svg", content: decodedData };
      } else if (mimeType) {
        return { type: "image", content: `data:${mimeType};base64,${base64Data}` };
      }

      return null; // Fallback if type cannot be determined
    } catch (error) {
      console.error("Error processing base64 data:", error);
      return null;
    }
  }

  getPageContent() { }

  render() {
    const {
      currentView,
      isDrawerShown,
      paymentMethod,
      paymentMethods,
      paymentInfo,
      isLoading,
      isMobile,
      paymentTransactionId,
      paymentFrameUrl,
      paymentQRCode,
      paymentWXCodeURL,
      error,
      alert,
      errorMessage,
      alertMessage,
      processingFee,
      errorHeading,
      orderId
    } = this.state;
    const logoSrc = paymentInfo && paymentInfo.merchant && paymentInfo.merchant.merchant_logo ? this.getLogoSrc(paymentInfo.merchant.merchant_logo) : null;

    return (
      <>
        {isLoading && <LoadingSpinner isFullScreen="true" />}

        {error && <ErrorPage customErrorMessage={errorMessage} customErrorHeading={errorHeading} />}

        {alert && <Alert type="warning" message={alertMessage} onClose={this.closeAlert} />}


        {!error && paymentInfo && paymentMethods && (
          <div className={`payment-page-wrapper ${isLoading ? "loading" : ""}`}>
            {paymentInfo && (
              <div className="payment-summary">
                <div className="heading">
                  <span className="header-text">{paymentInfo.merchant.name}</span>
                  {logoSrc ? (
                    logoSrc.type === "svg" ? (
                      <div className="header-logo-container">
                        <div
                          className="header-logo"
                          dangerouslySetInnerHTML={{ __html: logoSrc.content }}
                        />
                      </div>
                    ) : (
                      <div className="header-logo-container">
                        <img
                          className="header-logo"
                          src={logoSrc.content}
                          alt="Merchant Logo"
                        />
                      </div>
                    )
                  ) : (
                    <img className="header-logo" src="/assets/novatti_logo.svg" alt="Default Logo" />
                  )}
                </div>

                <PaymentSummary
                  paymentAmount={paymentInfo.gross_amount}
                  paymentUserFullName={this.getPaymentUserFullName(paymentInfo)}
                  paymentUserEmail={paymentInfo.customer.email}
                  paymentCustomerReferrence={paymentInfo.customer_reference}
                  paymentMethod={
                    currentView === "payment-form" ? paymentMethod : null
                  }
                  displayLineItems={paymentInfo.display_line_items}
                  lineItems={paymentInfo.line_items || []}
                  onGetProcessedFee={this.getProcessedFee.bind(this)}
                  getCardNumberCall={this.getCardNumber.bind(this)}
                />
              </div>
            )}

            {paymentMethods && (
              <div className="payment-page-content">
                <PageContent
                  currentView={currentView}
                  paymentMethods={paymentMethods}
                  isLoading={isLoading}
                  paymentInfo={paymentInfo}
                  paymentMethod={paymentMethod}
                  paymentTransactionId={paymentTransactionId}
                  paymentFrameUrl={paymentFrameUrl}
                  paymentQRCode={paymentQRCode}
                  paymentWXCodeURL={paymentWXCodeURL}
                  onPaymentMethodSelect={this.onPaymentMethodSelect.bind(this)}
                  onProceed={this.onPaymentMethodProceed.bind(this)}
                  onProcessedFeeCalculated={this.onProcessedFeeCalculated.bind(this)}
                  processingFee={this.state.processingFee}
                  onGetProcessedFee={this.getProcessedFee.bind(this)}
                  onCardNumberReceivedCall={this.onCardNumberReceived.bind(this)}
                  getCardNumberCall={this.getCardNumber.bind(this)}
                  onPollingError={this.onPollingError.bind(this)}
                  orderId={orderId}
                />
              </div>
            )}

            <div className="page-footer">
              <div class="powered-by">Powered by</div>
              <img src="/assets/novatti_logo.svg" alt="Novatti" width="80" />
              <div>Novatti Pty Ltd © {new Date().getFullYear()}</div>
            </div>
          </div>
        )}

        {/*
          todo move this to a component poratal
          ---
          portal can be opened using ReactDom.createPortal(<Component />, domNode);
          but closing mechanism is not working properly (when clicked on backdrop)
          same behavior will be needed for the modal as well
          */}
        {isMobile && isDrawerShown && paymentMethod && paymentInfo && (
          <SelectedPaymentDrawer
            paymentMethod={paymentMethod}
            paymentInfo={paymentInfo}
            onProceed={this.onPaymentMethodProceed.bind(this)}
            onExit={this.closeDrawer.bind(this)}
            processingFee={this.state.processingFee}
            onGetProcessedFee={this.getProcessedFee.bind(this)}
            getCardNumberCall={this.getCardNumber.bind(this)}
          />
        )}
      </>
    );
  }

  getPaymentUserFullName(paymentInfo) {
    return [paymentInfo.customer.first_name, paymentInfo.customer.last_name].filter(Boolean).join(' ');
  }
}

function getFaviconEl() {
  return document.getElementById("favicon");
}

export default PaymentApp;
