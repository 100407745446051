import {
  ALIPAY,
  CREDITCARD, DIRECTDEBIT, HPF_INIT_TOKEN, UNIONPAY, WECHATPAY
} from "./constants";
import ErrorPage from "../components/ErrorPage";


const CLIENT_KEY = process.env.REACT_APP_CLIENT_KEY;
const PAYMENT_INFO_API = process.env.REACT_APP_PAYMENT_INFO_API;  // /link
const PAYMENT_STATUS_API = process.env.REACT_APP_PAYMENT_STATUS_API;  // /paymentstatus
const PAYMENT_METHODS_API = process.env.REACT_APP_PAYMENT_METHODS_API   // /fee
const SURCHARGE_API = process.env.REACT_APP_SURCHARGE_API   // /surcharge
const PAYMENT_INIT_API = process.env.REACT_APP_PAYMENT_INIT_API;  // /initpayment
const HPF_TOKEN_INIT_API = process.env.REACT_APP_HPF_TOKEN_INIT_API;  // hpf-auth
const HPF_CLIENT_NAME = process.env.REACT_APP_HPF_CLIENT_NAME;
const ORDER_PAYMENT_STATUS_API = process.env.REACT_APP_ORDER_PAYMENT_STATUS_API;  // order-paymentstatus

export function surcharge(secureToken, accessToken, userId, alphaMid, amount, currency) {
  const payload = {
    'secure_token': secureToken,
    'merchant_id': alphaMid,
    'user_id': userId,
    'currency': currency,
    'amount': String(amount),
    'method': "CC",
    "channel_type": "07",
    "request_type": "surcharging",
    "alpha_token": sessionStorage.getItem(HPF_INIT_TOKEN)
  };
  const config = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(payload)
  }
  return fetch(SURCHARGE_API, config)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error();
      }
    }).then((data) => {
      return data
    });
}

export function fetchPaymentStatus(token, transactionId) {
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  }
  return fetch(PAYMENT_STATUS_API + `/${transactionId}`, config)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return {
        paymentTransaction: data,
      };
    });
}

export function fetchOrderPaymentStatus(token, orderId) {
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  }
  return fetch(ORDER_PAYMENT_STATUS_API + `/${orderId}`, config)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return {
        orderPaymentStatus: data,
      };
    });
}

export async function loadPaymentInfo(orderId) {
  const payload = {
    orderId,
    paymentPage: CLIENT_KEY,
  };
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };


  const response = await fetch(PAYMENT_INFO_API, config);
  if (!response.ok) {
    const errorResponse = await response.json();
    const { errorCode, message } = errorResponse;
    if (errorCode === 404) {
      throw new CustomError("INVALID_URL");
    } else if (errorCode === 410) {
      throw new CustomError("EXPIRED_URL", message);
    } else {
      throw new Error();
    }
  }
  const data = await response.json();
  return { isPaymentInfoLoaded: true, paymentInfo: data }
}

class CustomError extends Error {
  constructor(errorType, message) {
    super(message);
    this.type = errorType;
    this.message = message;
  }
}

export function loadPaymentMethods(token, amount) {
  const payload = {
    request_type: "fee_details",
    gross_amount: amount,
  };
  const config = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };
  return fetch(PAYMENT_METHODS_API, config)
    .then((res) => res.json())
    .then((data) => ({
      feeList: data,
    }));
}

export function fetchHPFInitToken(merchantId, token) {
  const payload = {
    "client-name": HPF_CLIENT_NAME,
    "merchant-id": merchantId,
  };
  const config = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };
  return fetch(HPF_TOKEN_INIT_API, config)
    .then((res) => res.json())
    .then((data) => {
      return { hpfInitToken: data.access_token, alphaMid: data.alpha_mid };
    });
}

export async function initializePayment(token, paymentMethod) {
  let payload;
  switch (paymentMethod.payment_method) {
    case CREDITCARD:
      return {};
    case DIRECTDEBIT:
      return {};
    case ALIPAY:
      payload = { "request_type": "payment", "payment_method": ALIPAY };
      break;
    case WECHATPAY:
      payload = { "request_type": "payment", "payment_method": WECHATPAY };
      break;
    case UNIONPAY:
      payload = { "request_type": "payment", "payment_method": UNIONPAY };
      break;
    default:
      payload = null;
  }

  if (!payload) {
    return {
      error: "Failed to process request",
    };
  }

  const rawResponse = await fetch(PAYMENT_INIT_API, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });

  if(rawResponse.status === 400){
    const errorBody = await rawResponse.json();
    return {
      responseCode: rawResponse.status,
      responseMessage: errorBody.message
    };

  }
  const responseCode = rawResponse.status;
  const responseBody = await rawResponse.json();
  switch (paymentMethod.payment_method) {
    case CREDITCARD:
      return {
        paymentFrameUrl: responseBody.iframe_url,
        paymentQRCode: null,
        paymentWXCodeURL: null,
        paymentTransactionId: responseBody.transaction_id,
      };
    case WECHATPAY:
      return {
        paymentFrameUrl: null,
        paymentQRCode: responseBody.qr_code,
        paymentWXCodeURL: responseBody.wx_code_url,
        paymentTransactionId: responseBody.transaction_id,
      };
    case ALIPAY:
      return {
        paymentFrameUrl: responseBody.page_url,
        paymentQRCode: responseBody.qr_code,
        paymentWXCodeURL: null,
        paymentTransactionId: responseBody.transaction_id,
      };
    case UNIONPAY:
      return {
        paymentFrameUrl: responseBody.page_url,
        paymentQRCode: null,
        paymentWXCodeURL: null,
        paymentTransactionId: responseBody.transaction_id,
      };
    default:
      return {
        paymentFrameUrl: null,
        paymentQRCode: null,
        paymentWXCodeURL: null,
        paymentTransactionId: null,
      };
  }
}

export function handleDirectDebitRequest(payload, token) {
  return fetch(PAYMENT_INIT_API, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  }).then((res) => {
    return { response: res.json(), status: String(res.status) };
  });
}

export function handleCreditPaymentRequest(request) {
  const { secureToken, bearerToken, paymentLinkToken, type,
    amount, userId, residentId, customerName, surchargeAmount, alphaToken, currency, issuerCountryCode } = request;
  const payload = {
    processor: "acquiring",
    type: type,
    secure_token: secureToken,
    bearer_token: bearerToken,
    payment_method: `creditcard`,
    request_type: "payment",
    currency: currency,
    amount: String(amount),
    user_id: userId,
    resident_id: residentId,
    customer_name: customerName,
    surcharge_amount: surchargeAmount,
    alpha_token: alphaToken,
    issuer_country_code: issuerCountryCode
  };
  return fetch(PAYMENT_INIT_API, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${paymentLinkToken}`,
    },
    body: JSON.stringify(payload),
  }).then((res) => {
    return { response: res.json(), status: String(res.status) };
  });
}