import React, { useEffect, useState } from "react";
import { handleDirectDebitRequest } from "../utils/RequestHandlers";
import FormField from "./FormField";
import LoadingSpinner from "./LoadingSpinner";
import { accountNumberPatternPassed, luhnCheckPassed } from "../utils/CommonUtils";
import { HTTP_STATUS_OK, PAYMENT_STATUS, TRANSACTION_STATUS } from "../utils/constants";

function DirectDebitForm(props) {
  const { paymentInfo, isDDCountryNz, processedFeeFetch, onPaymentStatusChanged } = props;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    account_name: null,
    account_bsb: null,
    account_no: null,
    customer_consent: true,
  });
  const [requestStatus, setRequestStatus] = useState(null);
  const [showTerms, setShowTerms] = useState(false);
  const validators = {
    account_name: (val) => {
      return val && val.length > 3 && val.length <= 32;
    },
    account_bsb: (val) => {
      return val && val.length > 4 && val.length <= 6 && /^\d+$/.test(val);
    },
    account_no: (val) => {
      if (isDDCountryNz) {
        return val
          && accountNumberPatternPassed(val)
          && !luhnCheckPassed(val.replace(/\D/g, ''));
      }
      return val && val.length > 6 && val.length <= 10 && /^\d+$/.test(val);
    },
  };

  const [accountName, setAccountName] = useState("");
  const [accountBsb, setAccountBsb] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [customerConsent, setCustomerConsent] = useState(false);
  const [termsTextColor, setTermsTextColor] = useState("#212529");

  const openTerms = (e) => {
    e.preventDefault();
    setShowTerms(true);
  };

  const closeTerms = (e) => {
    e.preventDefault();
    setShowTerms(false);
  };

  const resetForm = () => {
    setAccountName("");
    setAccountBsb("");
    setAccountNo("");
    setCustomerConsent(false);
    setLoading(false);
    setErrors({
      account_name: null,
      account_bsb: null,
      account_no: null,
      customer_consent: true,
    });
    setRequestStatus(null);
    setShowTerms(false)
  }

  const validateAndSetAccountName = (e) => {
    const val = e.target.value;
    errors.account_name = !validators.account_name(val);
    setAccountName(val);
  }

  const validateAndSetAccountBsb = (e) => {
    const val = e.target.value;
    errors.account_bsb = !validators.account_bsb(val);
    setAccountBsb(val);
  }

  const validateAndSetAccountNo = (e) => {
    const val = e.target.value;
    errors.account_no = !validators.account_no(val);
    setAccountNo(val);
  }

  const validateAndSetCustomerConsent = (e) => {
    const val = e.target.checked;
    errors.customer_consent = !val;
    setCustomerConsent(val);
  }


  const submit = (e) => {
    e.preventDefault();

    let formObj = {
      payload: {
        request_type: "payment",
        payment_method: "directdebit",
      }
    };

    let hasErrors = errors.account_name || errors.account_bsb || errors.account_no;
    if (hasErrors) {
      return;
    }
    if (!customerConsent) {
      setTermsTextColor("red");
      return;
    }

    //set form values to payload
    formObj.payload.account_name = accountName;
    formObj.payload.account_bsb = accountBsb;
    formObj.payload.account_no = accountNo;
    formObj.payload.customer_consent = customerConsent;
    formObj.payload.transaction_amount = paymentInfo.gross_amount;
    formObj.payload.surcharged_amount = processedFeeFetch() != null ? processedFeeFetch() * 1000 : 0;
    formObj.payload.currency = paymentInfo.currency;

    setLoading(true);
    handleDirectDebitRequest(formObj.payload, paymentInfo.access_token)
      .then((resp) => {
        if (resp.status == HTTP_STATUS_OK) {
          setRequestStatus("success");
          resp.response.then((data) => {
            onPaymentStatusChanged(PAYMENT_STATUS.SUCCESSFUL, data.transaction_id, data.transaction_status);
          });
        } else {
          setRequestStatus("failed");
          resp.response.then((data) => {
            onPaymentStatusChanged(PAYMENT_STATUS.FAILED, data.transaction_id, data.transaction_status || TRANSACTION_STATUS.ERROR);
          });
        }
      })
      .catch((err) => {
        setRequestStatus("failed");
        onPaymentStatusChanged(PAYMENT_STATUS.FAILED, "", TRANSACTION_STATUS.ERROR);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="pm-direct-debit">
      {loading && <LoadingSpinner />}

      {showTerms && <DirectDebitTerms onClose={closeTerms} setOuterConsent={setCustomerConsent} currentConsent={customerConsent} setTermsTextColor={setTermsTextColor} />}

      {!loading && requestStatus === "failed" && (
        PaymentFailedResponse(resetForm)
      )}

      {!loading && requestStatus === "success" && (
        PaymentSuccessResponse()
      )}

      {requestStatus === null && <form className="direct-debit-form" onSubmit={submit}>
        <FormField
          id="account_name"
          name="account_name"
          type="text"
          label="Account Name"
          errorMessage={
            errors.account_name ? "A valid account name is required." : ""
          }
          isRequired={true}
          maxLength={32}
          ariaDesc="account-name-message"
          value={accountName}
          onChange={validateAndSetAccountName}
        />

        {!isDDCountryNz && <FormField
          id="account_bsb"
          name="account_bsb"
          type="text"
          label="BSB"
          errorMessage={errors.account_bsb ? "A valid BSB is required." : null}
          isRequired={true}
          isNumber={true}
          maxLength={32}
          ariaDesc="account-bsb-message"
          value={accountBsb}
          onChange={validateAndSetAccountBsb}
        />}

        <FormField
          id="account_no"
          name="account_no"
          type="text"
          label="Account Number"
          errorMessage={
            errors.account_no ? "A valid account number is required." : null
          }
          isRequired={true}
          maxLength={32}
          ariaDesc="account-no-message"
          value={accountNo}
          onChange={validateAndSetAccountNo}
        />

        <div className="form-group text-center">
          <p style={{ color: termsTextColor, fontSize: "0.9rem", lineHeight: 1.6 }}>
            Please read and accept the{" "}
            <span style={{ fontWeight: "bold", cursor: "pointer" }} onClick={openTerms}>
              Terms and Conditions
            </span>{" "}
            before authorizing this Direct Debit request.
          </p>
        </div>

        <div id="direct-debit-message" className="error-message"></div>

        <div className="m-top-2">
          <button type="submit" className="btn-primary" disabled={loading}>
            Submit Request
          </button>
        </div>
      </form>}
    </div>
  );
}

function DirectDebitTerms(props) {
  const { onClose, setOuterConsent, currentConsent, setTermsTextColor } = props;

  const [customerConsent, setCustomerConsent] = useState(false);

  useEffect(() => {
    setCustomerConsent(currentConsent);
  }, [])

  return (
    <div className="modal-wrapper direct-debit-terms-modal">
      <div className="modal-content">
        <div className="modal-header">Direct Debit - Terms and Conditions</div>

        <div className="modal-body">
          <p>
            I may ask my bank to reverse a direct debit up to 120 calendar days after the debit if:
          </p>
          <div className="mx-4 mb-3">
            <ul style={{ listStyleType: "circle" }}>
              <li>I don’t receive a written notice of the amount and date of each direct debit from the initiator, or</li>
              <li>I receive a written notice but the amount or the date of debiting is different from the amount or the
                date specified on the notice.</li>
            </ul>
          </div>


          <p>
            For a series of direct debits, the initiator is required to give a written notice of the amount and date of each
            direct debit in the series no less than 10 calendar days before the date of the first direct debit in the series. The notice is to
            include:
          </p>

          <div className="mx-4 mb-3">
            <ul style={{ listStyleType: "circle" }}>
              <li>the dates of the debits, and </li>
              <li>the amount of each direct debit.</li>
              <li>If the initiator proposes to change an amount or date of a direct debit specified in the notice, the initiator is
                required to give you notice no less than 30 calendar days before the change.</li>
            </ul>
          </div>

          <p>
            If you have specifically requested direct debits and have agreed the amount of the direct debit, the initiator is
            required to give you a written notice of the amount and date of the direct debit no later than the date of the debit.
          </p>

          <p>
            If the bank dishonours a direct debit but the initiator sends the direct debit a second time within 5 business days
            of the original direct debit, the initiator is not required to notify you a second time of the amount and date of
            the direct debit.
          </p>

          <div className="d-flex justify-content-center">
            <input
              type="checkbox"
              className="form-check-input mx-2"
              id="customer_consent"
              name="customer_consent"
              checked={customerConsent}
              onChange={(event) => {
                let checked = event.target.checked;
                if (checked) {
                  setTermsTextColor("#212529")
                }
                setCustomerConsent(checked);
                setOuterConsent(checked);
              }}
            />
            I agree to the terms and conditions
          </div>
        </div>

        <div className="modal-footer">
          <div className="d-flex justify-content-end">
            <button className="btn btn-secondary" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const PaymentFailedResponse = ({ retry }) => (
  <>
    <div className="d-flex justify-content-center">
      <img src="/assets/failed.svg" alt="Novatti" width="80" />
    </div>
    <div className="d-flex justify-content-center mt-3">
      <h2 style={{ color: "#EF6060" }}>Payment Failed!</h2>
    </div>
    <div className="d-flex justify-content-center">
      <button
        type="button"
        className="btn btn-danger btn-block shadow-sm"
        id="paymentButton"
        onChange={retry}
      >
        Please Retry
      </button>
    </div>
  </>
);

const PaymentSuccessResponse = () => (
  <>
    <div className="d-flex justify-content-center">
      <img src="/assets/success.svg" alt="Novatti" width="80" />
    </div>
    <div className="d-flex justify-content-center mt-3">
      <h2 style={{ color: "#09c04c" }}>Payment Successful!</h2>
    </div>
  </>

);

export default DirectDebitForm;
