import React, { useState } from "react";
import _ from 'lodash'
import PaymentForm from "./PaymentForm";
import PaymentInvoice from "./PaymentInvoice";
import PaymentMethodList from "./PaymentMethodList";
import HPFForm from "./HPFForm";
import { CREDITCARD, DIRECTDEBIT, WECHATPAY, PAYMENT_STATUS, ALIPAY, PM_STATIC_DATA } from "../utils/constants";
import DirectDebitForm from "./DirectDebitForm";
import WeChatPayQR from "./WeChatPayQR";
import AlipayPlusQR from "./AlipayPlusQR";
import { updateQueryParams } from "../utils/CommonUtils";

function PageContent(props) {
  const {
    currentView,
    paymentMethods,
    paymentMethod,
    paymentTransactionId,
    paymentFrameUrl,
    paymentQRCode,
    paymentWXCodeURL,
    paymentInfo,
    onPaymentMethodSelect,
    onProceed,
    onProcessedFeeCalculated,
    processingFee,
    onGetProcessedFee,
    onCardNumberReceivedCall,
    getCardNumberCall,
    onPollingError,
    orderId
  } = props;

  let showFees = _.get(paymentInfo, 'merchant.page_configuration.show_fees', false);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showPaymentFailure, setShowPaymentFailure] = useState(false);

  const onPaymentStatusChanged = (paymentStatus = "", transactionId = "", transactionStatus = "") => {
    let redirectUrls = paymentInfo?.redirect_urls;

    let redirectUrl = "";
    switch (paymentStatus) {
      case PAYMENT_STATUS.SUCCESSFUL:
        redirectUrl = redirectUrls?.successful;
        setShowPaymentFailure(false);
        setShowPaymentSuccess(true);
        break;

      case PAYMENT_STATUS.FAILED:
        redirectUrl = redirectUrls?.failed;
        setShowPaymentFailure(true);
        setShowPaymentSuccess(false);
        break;

      case PAYMENT_STATUS.CANCELLED:
        redirectUrl = redirectUrls?.cancelled;
        setShowPaymentFailure(true);
        setShowPaymentSuccess(false);
        break;
      default:
        break;
    }

    if (!redirectUrls) return;

    if (redirectUrl) {
      let newParams = [
        { key: "orderId", value: orderId },
        { key: "transactionId", value: transactionId || paymentTransactionId },
        { key: "transactionStatus", value: transactionStatus }
      ];
      redirectUrl = updateQueryParams(redirectUrl, newParams);
      setTimeout(() => window.location.replace(redirectUrl), 2000);
    }
  }

  const retry = () => {
    window.location.reload();
  }

  function processView() {
    return <div className="payment-invoice-wrapper">
      <PaymentInvoice
        paymentInfo={paymentInfo}
        paymentMethod={paymentMethod}
        processingFee={processingFee}
        processedFeeFetch={onGetProcessedFee.bind(this)}
        getCardNumberCallCall={getCardNumberCall.bind(this)}
      />

      {"payment-form" !== currentView && <div>
        <button
          className="btn-primary"
          disabled={!paymentMethod}
          onClick={onProceed.bind(this)}
        >
          Proceed
        </button>

        <div className="warning-message">
          * Please select a payment method to proceed
        </div>
      </div>}

      {"payment-form" === currentView && <div>
        {(!showPaymentSuccess && !showPaymentFailure) &&
          <div>
            { 
              WECHATPAY === paymentMethod.payment_method && 
              <WeChatPayQR
                qrCode={paymentQRCode}
                qrCodeUrl={paymentWXCodeURL}
              />
            }
            {
              paymentQRCode && ALIPAY === paymentMethod.payment_method &&
              <AlipayPlusQR
                qrCode={paymentQRCode}
              />
            }
          </div>
        }
        {
          (WECHATPAY === paymentMethod.payment_method || ALIPAY === paymentMethod.payment_method) 
            && showPaymentSuccess && paymentSuccessResponse(paymentDisplayName(paymentMethod.payment_method, paymentQRCode))
        }
        {
          (WECHATPAY === paymentMethod.payment_method || ALIPAY === paymentMethod.payment_method) 
            && showPaymentFailure && paymentFailedResponse(retry)
        }
      </div>}

    </div>;
  }

  const paymentDisplayName = (paymentMethod, paymentQRCode) => {
    if (paymentQRCode && ALIPAY === paymentMethod) {
      return 'Alipay+';
    }
    return PM_STATIC_DATA[paymentMethod]?.title;
  }

  const paymentSuccessResponse = (paymentName) => (
    <>
      <div className="d-flex justify-content-center">
        <img src="/assets/success.svg" alt="Novatti" width="80"/>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <h2 style={{color: "#09c04c"}}>Payment Successful!</h2>
      </div>
      {
        paymentName &&
        <div className="d-flex justify-content-center mt-3">
          <div style={{fontSize: 12}}>Payment method: {paymentName}.</div>
        </div>
      }
    </>
  );

  const paymentFailedResponse = (retry) => (
    <>
      <div className="d-flex justify-content-center">
        <img src="/assets/failed.svg" alt="Novatti" width="80"/>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <h2 style={{color: "#EF6060"}}>Payment Failed!</h2>
      </div>
      <div className="d-flex justify-content-center">
        <button
            type="button"
            className="btn btn-danger btn-block shadow-sm"
            id="paymentButton"
            onClick={retry}
        >
          Please Retry
        </button>
      </div>
    </>
);

  function processCreditCardView() {
    return <div className="payment-invoice-wrapper">
      <PaymentInvoice
        paymentInfo={paymentInfo}
        paymentMethod={paymentMethod}
        processingFee={processingFee}
        processedFeeFetch={onGetProcessedFee.bind(this)}
        getCardNumberCallCall={getCardNumberCall.bind(this)}
      />

      <HPFForm
        paymentInfo={paymentInfo}
        paymentLinkToken={paymentInfo.access_token}
        merchantId={paymentInfo.merchant.merchant_id}
        type={paymentMethod.payment_method.split("-")[1]}
        onCalculated={onProcessedFeeCalculated.bind(this)}
        onCardNumberReceivedCallCall={onCardNumberReceivedCall.bind(this)}
        onPaymentStatusChanged={onPaymentStatusChanged}
      />
    </div>;
  }

  function processDirectDebitView() {
    return <div className="payment-invoice-wrapper">
      <PaymentInvoice
        paymentInfo={paymentInfo}
        paymentMethod={paymentMethod}
        processingFee={processingFee}
        processedFeeFetch={onGetProcessedFee.bind(this)}
        getCardNumberCallCall={getCardNumberCall.bind(this)}
      />

      <DirectDebitForm
        paymentInfo={paymentInfo}
        isDDCountryNz={paymentInfo.dd_country === "NZ"}
        processedFeeFetch={onGetProcessedFee.bind(this)}
        onPaymentStatusChanged={onPaymentStatusChanged}
      />
    </div>;
  }

  return (
    <div>
      <div className="payment-method-selection">
        <div className="payment-method-list-wrapper">
          <PaymentMethodList
            paymentInfo={paymentInfo}
            showFees={showFees}
            paymentMethods={paymentMethods}
            paymentMethod={paymentMethod}
            compactUIEnabled={!_.isEmpty(paymentFrameUrl)}
            onSelect={onPaymentMethodSelect.bind(this)}
            showPaymentSuccess={showPaymentSuccess}
          />
        </div>

        {<div className="flex-spacer"></div>}

        {!paymentMethod && processView.call(this)}

        {paymentMethod
          && (paymentMethod.payment_method !== CREDITCARD && paymentMethod.payment_method !== DIRECTDEBIT)
          && processView.call(this)
        }

        {paymentMethod
          && (paymentMethod.payment_method === CREDITCARD)
          && processCreditCardView.call(this)}

        {paymentMethod
          && (paymentMethod.payment_method === DIRECTDEBIT)
          && processDirectDebitView.call(this)}

      </div>
      {"payment-form" === currentView && <PaymentForm
        paymentInfo={paymentInfo}
        paymentMethod={paymentMethod}
        paymentTransactionId={paymentTransactionId}
        paymentFrameUrl={paymentFrameUrl}
        paymentQRCode={paymentQRCode}
        paymentWXCodeURL={paymentWXCodeURL}
        onCalculatedProp={onProcessedFeeCalculated.bind(this)}
        onCardNumberReceivedC={onCardNumberReceivedCall.bind(this)}
        processedFeeFetch={onGetProcessedFee.bind(this)}
        onPollingError={onPollingError}
        onPaymentStatusChanged={onPaymentStatusChanged}
      />}
    </div>
  );
}

export default PageContent;
