import React from "react";
import _ from "lodash";

function ErrorPage(props) {
  const { customErrorMessage, customErrorHeading } = props;
  const containsCustomErrorMessage = !_.isEmpty(customErrorMessage);
  const containsCustomErrorHeading = !_.isEmpty(customErrorHeading);
  const refresh = () => {
    window.location.reload();
  };

  return (
    <div className="error-page-wrapper">
      <div className="error-page">

        {containsCustomErrorHeading && <div className="title">{customErrorHeading}</div>}
        {!containsCustomErrorHeading && <div className="title">Oops...</div>}

        {containsCustomErrorMessage && <div className="message" style={{textAlign: "center"}}>
          {customErrorMessage}
        </div>}
        {!containsCustomErrorMessage && <div className="message">
          Something went wrong. We couldn't load the payment page. Please try
          again.
        </div>}

        <button className="action btn-primary-border" onClick={refresh}>
          Reload
        </button>
      </div>

      <div className="page-footer">
        <img src="/assets/novatti_logo.svg" alt="Novatti" width="100" />
        <div>Novatti Pty Ltd © {new Date().getFullYear()}</div>
      </div>
    </div>
  );
}

export default ErrorPage;
