import React from "react";

function LoadingSpinner(props) {
  const { isFullScreen } = props;
  return (
    <div className={`loading-spinner-wrapper ${isFullScreen ? 'fullscreen' : ''}`}>
      <div className="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default LoadingSpinner;
