export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function isMobile() {
  return getWindowDimensions().width <= 768;
}

export const refresh = () => {
  window.location.reload();
};

export const luhnCheckPassed = (cardNumber) => {
  if (cardNumber) {
    const cardNumberArr = cardNumber
      .replace(/\D/g, '')
      .split('');
    const sum = cardNumberArr
      .reduce((acc, ele, idx) => {
        var indexFromLast = cardNumber.length - 1 - idx;
        var elementFromLast = cardNumberArr[indexFromLast];
        if ((cardNumber.length - indexFromLast) % 2 === 0) {
          var timesTwo = parseInt(elementFromLast) * 2;
          acc += ((timesTwo - timesTwo % 10) / 10 + timesTwo % 10);
        } else {
          acc += parseInt(elementFromLast);
        }

        return acc;

      }, 0);

    return sum % 10 === 0;
  }
  return false;
};

export const accountNumberPatternPassed = (accountNumber) => {
  return /(^[0-9]{15,16}|^[0-9]{2}\-[0-9]{4}\-[0-9]{7}\-[0-9]{2,3})$/.test(accountNumber)
}

export const updateQueryParams = (url, newParams = []) => {
  let newUrl = new URL(url);
  let params = new URLSearchParams(newUrl.search);
  newParams.forEach(item => {
    params.set(item.key, item.value);
  });
  newUrl.search = params.toString();
  return newUrl.toString();
}