import React from "react";
import {PM_STATIC_DATA, ALIPAY} from "../utils/constants";

const AlipayPlusQR = ({qrCode}) => {
    return (<div className="we-chat-pay-div">
        <div style={{paddingBottom: '20px'}}>
            <img src={PM_STATIC_DATA[ALIPAY].secondaryLogo} width="180"
                 alt={PM_STATIC_DATA[ALIPAY].title}
                 style={{paddingTop: '20px', marginLeft: '-10px'}}/>
        </div>

        <img id="we-chat-pay-qr-code" src={`data:image/png;base64,${qrCode}`} width="250" alt="qr code"/>
        <div style={{paddingTop: '20px'}}>Scan QR code with Alipay App</div>
    </div>)
}

export default AlipayPlusQR;