import { create } from 'zustand'
import { devtools, redux } from 'zustand/middleware'
import {isMobile} from "../utils/CommonUtils";
import {loadPaymentInfo, loadPaymentMethods} from "../utils/RequestHandlers";
import {LINK_ACCESS_TOKEN} from "../utils/constants";

const usePageDataStore = create(devtools((set) => ({
    // flags
    isMobile: isMobile(),
    isLoading: true,
    currentView: "payment-method-selection",
    isDrawerShown: false,
    isPaymentInfoLoaded: false,
    isPaymentMethodsLoaded: false,
    error: false,
    alert: false,
    // data
    paymentInfo: null,
    paymentMethods: [],
    paymentMethod: null,
    paymentTransactionId: null,
    paymentFrameUrl: null,
    paymentQRCode: null,
    paymentWXCodeURL: null,
    processedFee: null,
    cardNumber: null,
    errorMessage: null,
    alertMessage: null,
    setInitialData:  (params) => {
        set((state) => ({
            ...state,
            paymentInfo: params.paymentInfo,
            paymentMethods: params.paymentMethods,
            isLoading: false,
            paymentMethodsFeeList: params.paymentMethodsFeeList.feeList,
        }))
    }
})))

function getFaviconEl() {
    return document.getElementById("favicon");
}

export default usePageDataStore;