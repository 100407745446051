import React from "react";
import {PM_STATIC_DATA, WECHATPAY} from "../utils/constants";

const WeChatPayQR = ({qrCode, qrCodeUrl}) => {
    return (<div className="we-chat-pay-div">
        <a id="we-chat-pay-link-1" href={qrCodeUrl}><img src={PM_STATIC_DATA[WECHATPAY].secondaryLogo} width="180"
                                                         alt={PM_STATIC_DATA[WECHATPAY].title}
                                                         style={{paddingTop: '20px', marginLeft: '-10px'}}/></a>
        <div style={{paddingTop: '15px'}}><a id="we-chat-pay-link-2" href={qrCodeUrl}>Click here to pay via WeChat App</a>
        </div>

        <img id="we-chat-pay-qr-code" src={`data:image/png;base64,${qrCode}`} width="250" alt="qr code"/>
        <div style={{paddingTop: '20px'}}>Scan QR code with WeChat App</div>
    </div>)
}

export default WeChatPayQR;